import { Container } from '@finn/atoms';
import DirectCheckoutConfirmation from '@finn/auto-ui/components/ProductDetails/modals/DirectCheckoutConfirmation';
import { Interlink } from '@finn/auto-ui/modules/products/details';
import { CarInformationDrawer } from '@finn/auto-ui/modules/products/details/components/CarInformationDrawer/CarInformationDrawer';
import { SpacingWrapper } from '@finn/platform-modules';
import { Features, useIsABVariant, useIsCVariant } from '@finn/ua-featureflags';
import { ModalContainer, ModalKey, useOpenModal } from '@finn/ua-modals';
import { GenericVehicleDetails, Review } from '@finn/ua-vehicle';
import { isMobileApp } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import React, { ReactNode } from 'react';

import KeyData from '~/components/KeyData/KeyData';
import ProductDetails from '~/components/ProductDetails';
import { AddToCartProvider } from '~/components/ProductDetails/AddToCartProvider';
import { ErrorPageData, PDPData, PDPModules } from '~/types/cosmicModules';

import { DownloadButtons } from '../Equipment/Equipment';
import PageBreadcrumbs from '../PageBreadcrumbs';
import { ComparisonTable } from './ComparisonTable';
import { CoscmicModules } from './CoscmicModules';
import { EquipmentDetailsComponent } from './EquipmentDetailsComponent';
import { FrequentlyAskedQuestions } from './FrequentlyAskedQuestions';
import { MetaContent } from './MetaContent';
import { ProductSlider } from './ProductSlider';
import { RecommendedVehicles } from './RecommendedVehicles';
import { TrustPilot } from './TrustPilot';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  mobileAppContainer: {
    marginTop: theme.spacing(3),
  },
}));

type Props = {
  vehicle: GenericVehicleDetails;
  reviews: Review[];
  pdpModules: PDPModules;
  pageData: PDPData | ErrorPageData;
  renderMetaContent?: () => ReactNode;
};

const VehicleData: React.FC = () => {
  return (
    <Container>
      <KeyData />
    </Container>
  );
};

export const ProductDetailsContainer: React.FC<Props> = ({
  vehicle,
  reviews,
  pdpModules,
  pageData,
  renderMetaContent,
}) => {
  const classes = useStyles();
  const openModal = useOpenModal();
  const isApp = isMobileApp();

  const isExpRecommendations = useIsABVariant(Features.ExpPDPRecommendations);
  const isReducedContentBVariant = useIsABVariant(Features.ExpReducedContent);
  const isReducedContentCVariant = useIsCVariant(Features.ExpReducedContent);
  const isReducedVariant = isReducedContentBVariant || isReducedContentCVariant;
  const isExpPDPCarInfo = useIsABVariant(Features.ExpPDPCarInfo);

  const productPageData = pageData as PDPData;
  const productDetailsData =
    productPageData?.metadata?.content?.product_details ?? {};

  return (
    <div
      className={[classes.container, isApp && classes.mobileAppContainer].join(
        ' '
      )}
      data-appid="product-details"
    >
      <MetaContent renderMetaContent={renderMetaContent} />
      <Container>
        <PageBreadcrumbs />
      </Container>
      <AddToCartProvider vehicle={vehicle}>
        <ProductDetails
          pageData={productPageData}
          hideBreadCrumbs={isApp}
          handleMoreInformationClick={
            isExpPDPCarInfo && (() => openModal(ModalKey.PDP_CAR_INFO))
          }
        />

        {isExpRecommendations && (
          <RecommendedVehicles id={vehicle.uid} pdpModules={pdpModules} />
        )}
        {!isExpRecommendations && <ProductSlider pdpModules={pdpModules} />}

        {!isExpPDPCarInfo && (
          <>
            <VehicleData />
            {!isReducedContentCVariant ? (
              <EquipmentDetailsComponent
                productDetailsData={productDetailsData}
              />
            ) : (
              <Container>
                <div className="mt-4">
                  <DownloadButtons productDetailsData={productDetailsData} />
                </div>
              </Container>
            )}
          </>
        )}

        {isReducedVariant ? (
          <>
            <ComparisonTable pdpModules={pdpModules} />
            <TrustPilot pdpModules={pdpModules} reviews={reviews} />
            <FrequentlyAskedQuestions pdpModules={pdpModules} />
          </>
        ) : (
          <CoscmicModules
            vehicle={vehicle}
            reviews={reviews}
            pdpModules={pdpModules}
          />
        )}

        <SpacingWrapper />
        {!isReducedVariant && <Interlink vehicle={vehicle} />}

        <ModalContainer
          modalKey={ModalKey.DIRECT_CHECKOUT_CONFIRMATION_PDP}
          ModalComponent={DirectCheckoutConfirmation}
          pageData={productPageData}
        />

        {isExpPDPCarInfo && (
          <ModalContainer
            modalKey={ModalKey.PDP_CAR_INFO}
            ModalComponent={CarInformationDrawer}
            productDetailsData={productDetailsData}
          />
        )}
      </AddToCartProvider>
    </div>
  );
};
