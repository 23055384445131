import { ToggleGroup, ToggleItem } from '@finn/design-system';
import { Features, useIsABVariant, useIsCVariant } from '@finn/ua-featureflags';
import { InfoToolTip } from '@finn/ui-components';
import { getForBusinessCookie } from '@finn/ui-modules';
import { getFormattedPrice, getTermLabel } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import React, { useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import PDPTracking from '~/components/ProductDetails/utils/PDPTracking';
import { useTrackingData } from '~/components/ProductDetails/utils/useTrackingData';
import VehicleContext, { OneTimePrice } from '~/contexts/Vehicle';
import { getTermsPrices } from '~/utils/pdp';

import { useConfigureStore } from '../ConfigurePageStore';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& > span': {
      marginLeft: 10,
    },
  },
}));

interface IProps {
  className?: string;
}

const ContractDuration: React.FunctionComponent<
  React.PropsWithChildren<IProps>
> = ({ className, children }) => {
  const classes = useStyles();

  const i18n = useIntl();

  const isBVariant = useIsABVariant(Features.ExpDownPaymentOptions);
  const isCVariant = useIsCVariant(Features.ExpDownPaymentOptions);
  const isBVariantNoTogglePrices = useIsABVariant(
    Features.ExperimentPCPNoTogglePrices
  );

  const { vehicle } = useContext(VehicleContext);

  const { setTerm, term, downPayment, downPaymentAmount } = useConfigureStore();

  const isExperimentDownPayment = isBVariant || (isCVariant && term > 1);

  const isForBusiness = getForBusinessCookie();

  const durations = getTermsPrices(
    vehicle,
    isForBusiness ? 'b2b' : 'b2c',
    downPayment,
    isExperimentDownPayment,
    downPaymentAmount
  );

  const tooltipContent = i18n.formatMessage({
    id: 'checkout.pageData.term_tooltip_content',
  });

  const isDisplayTooltip = durations.some(
    (duration) => duration.duration === 1
  );

  const flexTermLabel = i18n.formatMessage({
    id: 'checkout.pageData.flex_term_label',
  });

  const contractTermLabel = i18n.formatMessage({
    id: 'pdp.contractTerm',
  });

  const trackingData = useTrackingData();

  const trackDurationChange = useCallback(
    (duration: string) => {
      PDPTracking.contractTermClicked({ ...trackingData, term: duration });
    },
    [trackingData]
  );

  const onChangeDuration = useCallback(
    (oneTimeValue: OneTimePrice) => {
      trackDurationChange(String(oneTimeValue.duration));

      setTerm(oneTimeValue.duration);
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [trackDurationChange]
  );

  const durationsMap = useMemo(
    () =>
      durations.reduce(
        (acc, duration) => {
          acc[String(duration.duration)] = duration;

          return acc;
        },
        {} as Record<string, OneTimePrice>
      ),
    [durations]
  );

  return (
    <div className={className} translate="no">
      <div className={classes.title}>
        <h6
          className={`${children ? 'global-t5-semibold' : 'global-t6-semibold'}`}
        >
          {contractTermLabel}
        </h6>

        {isDisplayTooltip && (
          <span>
            <InfoToolTip content={tooltipContent} />
          </span>
        )}
      </div>
      {children}

      <ToggleGroup
        className="mt-1"
        cols={durations.length > 1 ? 2 : 1}
        defaultValue={String(term)}
        value={String(term)}
        onValueChange={(value) => {
          if (value) {
            return onChangeDuration(durationsMap[value]);
          }
        }}
      >
        {durations.map((oneTimeValue) => (
          <ToggleItem
            key={oneTimeValue.duration}
            variant="regular"
            title={getTermLabel(oneTimeValue.duration, flexTermLabel, i18n)}
            value={String(oneTimeValue.duration)}
            description={
              isBVariantNoTogglePrices
                ? null
                : getFormattedPrice(i18n, oneTimeValue.price, 0)
            }
          />
        ))}
      </ToggleGroup>
    </div>
  );
};

export default ContractDuration;
