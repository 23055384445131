import { Button, Input } from '@finn/design-system';
import { Close } from '@finn/design-system/icons/close';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ui-modules';
import { cn, useSession } from '@finn/ui-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Dialog,
  FormHelperText,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import React, { memo, useCallback, useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import NotifySuccess from '~/components/UserAccount/Modals/NotifyMeModal/NotifySuccess';
import { useNotifyMeModalStyles } from '~/components/UserAccount/Modals/NotifyMeModal/useNotifyMeModalStyles';
import VehicleContext, { GenericVehicleDetails } from '~/contexts/Vehicle';
import { useCurrentLocale } from '~/hooks/useCurrentLocale';
import { createUnavailableDeal } from '~/services/checkout';
import { ClientType, ComingSoonDealCreationBody } from '~/types/checkout';
import { AvailabilityStatusUS } from '~/utils/asyncValidations';

// TODO design-system: refactor !important styles after migration to new typography APP-1443
import {
  FormInputs,
  FormInputsDeal,
  getValidationScemaForFormType,
  initialValuesDeal,
} from './config';
import { useNotifyMeModalStore } from './useNotifyMeModalStore';

type Props = {
  open: boolean;
  onClose(): void;
  vehicle?: GenericVehicleDetails;
  locationErrorType?: AvailabilityStatusUS;
  prefillData?: FormInputsDeal;
  isOutOfStock?: boolean;
};

const NotifyMeModal: React.FC<Props> = ({
  open,
  onClose,
  vehicle,
  locationErrorType,
  prefillData,
  isOutOfStock,
}) => {
  const classes = useNotifyMeModalStyles();
  const i18n = useIntl();
  const [session] = useSession();
  const {
    activeForm,
    setActiveForm,
    checkEmail,
    submitLogin,
    submitError,
    setSubmitError,
    subscribedList,
    addToSubscribedList,
  } = useNotifyMeModalStore();
  const { locale } = useCurrentLocale();
  const { oneTimePrice } = useContext(VehicleContext);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );
  const validationSchema = getValidationScemaForFormType(activeForm);
  const form = useForm<FormInputs>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: prefillData ?? initialValuesDeal,
  });
  const vehicleId = vehicle?.id;
  const vehicleIdForLocation =
    locationErrorType !== 'UNSUPPORTED_AREA' ? vehicle?.id : undefined;
  const term = oneTimePrice?.duration;
  useEffect(() => {
    if (session) {
      setActiveForm('none');
    }
  }, [session, setActiveForm]);
  const shouldShowEmailField = ['email', 'login', 'guest'].includes(activeForm);
  const shouldShowNameFields = activeForm === 'guest';
  const shouldShowPasswordField = activeForm === 'login';

  const subscriptionKey = `${vehicleId}-${prefillData?.zipCode || ''}`;

  const submitHandler = useCallback(async () => {
    const formData = form.getValues();
    setSubmitError(null);

    if (activeForm === 'email') {
      interactionTrackingEvent(TrackingEventName.CTA_CLICKED, {
        location: 'cta-notify',
        action: 'check-email',
      });
      await checkEmail(formData.email);

      return;
    }

    if (activeForm === 'login') {
      interactionTrackingEvent(TrackingEventName.CTA_CLICKED, {
        location: 'cta-notify',
        action: 'check-login',
      });
      await submitLogin(formData.email, formData.password);

      return;
    }

    interactionTrackingEvent(TrackingEventName.CTA_CLICKED, {
      location: 'cta-notify',
      action: 'submit-notify-me',
    });

    interactionTrackingEvent(TrackingEventName.COMING_SOON_LEAD_SUBMITTED, {
      contentIds: [vehicle.id],
      make: vehicle.brand.id,
      model: vehicle.model,
      email: formData?.email,
    });

    const dealInfo: ComingSoonDealCreationBody = {
      firstname: formData.firstName,
      lastname: formData.lastName,
      email: formData.email,
      type: isOutOfStock ? ClientType.BACK_IN_STOCK : ClientType.COMING_SOON,
      vehicleId,
      term: term || 12,
    };

    try {
      const res = await createUnavailableDeal(dealInfo, {
        locale,
      });
      if (res.status === 200) {
        setSubmitError(false);
        addToSubscribedList(subscriptionKey);
      } else {
        setSubmitError(true);
      }
    } catch {
      setSubmitError(true);
    }
  }, [form, vehicleId, term, vehicleIdForLocation, locale, activeForm]);
  const onModalClose = useCallback(() => {
    onClose();
    setActiveForm('email');
    // timeout so that modal can close without resetting thank you message
    setTimeout(() => {
      setSubmitError(null);
    }, 1000);
  }, [onClose, setActiveForm, setSubmitError]);

  const title = i18n.formatMessage({
    id: `general.notifyMeModal.comingSoon.formTitle`,
  });

  useEffect(() => {
    if (session && session.user) {
      form.setValue('firstName', session.user.hs_first_name);
      form.setValue('lastName', session.user.hs_last_name);
      form.setValue('email', session.user.email);
    }
  }, [session, form]);

  const descriptionKey = session
    ? 'formDescriptionLoggedIn'
    : 'formDescription';

  const description = i18n.formatMessage(
    { id: `general.notifyMeModal.comingSoon.${descriptionKey}` },
    {
      location: null,
      car: vehicle ? `${vehicle.brand?.id} ${vehicle.model}` : '',
    }
  );

  const shouldShowContinueLabel = activeForm === 'email';
  const shouldShowLoginLabel = activeForm === 'login';

  const ctalabelContinue = i18n.formatMessage({ id: 'pdp.continueToBooking' });
  const ctalabelLogin = i18n.formatMessage({ id: 'checkout.loginTitle' });

  const ctaLabelNotifyMe = i18n.formatMessage({
    id: `general.notifyMeModal.comingSoon.formCtaLabel`,
  });

  const ctaLabel = shouldShowContinueLabel
    ? ctalabelContinue
    : shouldShowLoginLabel
      ? ctalabelLogin
      : ctaLabelNotifyMe;

  return (
    <Dialog
      open={open}
      onClose={onModalClose}
      fullScreen={isMobile}
      maxWidth={false}
      PaperProps={{
        classes: {
          root: classes.paperRoot,
        },
      }}
    >
      <Button
        variant="ghost"
        onClick={onModalClose}
        className={cn(classes.closeButton, 'h-max rounded-full')}
      >
        <Close />
      </Button>
      {subscribedList.includes(subscriptionKey) ? (
        <NotifySuccess vehicle={vehicle} onClose={onClose} />
      ) : (
        <form onSubmit={form.handleSubmit(submitHandler)}>
          <h4 className="mobile-t4-semibold md:web-t4-semibold mb-6 max-w-[85%]">
            {title}
          </h4>
          {description && <p className="body-16-light mb-6">{description}</p>}

          {session && (
            <Box marginBottom={3}>
              <p
                className={cn('body-14-regular text-iron')}
              >{`${session.user.hs_first_name} ${session.user.hs_last_name}`}</p>
              <p className={cn('body-14-regular text-iron mt-2')}>
                {session.user.email}
              </p>
            </Box>
          )}
          {shouldShowNameFields && (
            <div className={classes.nameInputGroup}>
              <div className={classes.relative}>
                <Controller
                  control={form.control}
                  name="firstName"
                  render={({ field, fieldState }) => (
                    <Input
                      label={i18n.formatMessage({
                        id: 'pdp.comingSoon.firstNameLabel',
                      })}
                      {...field}
                      error={
                        !!fieldState.error &&
                        fieldState.isTouched &&
                        i18n.formatMessage({
                          id: fieldState.error.message,
                        })
                      }
                    />
                  )}
                />
              </div>
              <div className={classes.relative}>
                <Controller
                  control={form.control}
                  name="lastName"
                  render={({ field, fieldState }) => (
                    <Input
                      label={i18n.formatMessage({
                        id: 'pdp.comingSoon.lastNameLabel',
                      })}
                      {...field}
                      error={
                        !!fieldState.error &&
                        fieldState.isTouched &&
                        i18n.formatMessage({
                          id: fieldState.error.message,
                        })
                      }
                    />
                  )}
                />
              </div>
            </div>
          )}
          {shouldShowEmailField && (
            <Box marginBottom={4}>
              <Controller
                control={form.control}
                name="email"
                render={({ field, fieldState }) => (
                  <Input
                    label={i18n.formatMessage({
                      id: 'pdp.comingSoon.emailLabel',
                    })}
                    {...field}
                    error={
                      !!fieldState.error &&
                      fieldState.isTouched &&
                      i18n.formatMessage({
                        id: fieldState.error.message,
                      })
                    }
                  />
                )}
              />
              {shouldShowPasswordField && (
                <Box mt={2}>
                  <Controller
                    control={form.control}
                    name="password"
                    render={({ field, fieldState }) => (
                      <Input
                        label={i18n.formatMessage({
                          id: 'userAccount.field.password',
                        })}
                        type="password"
                        {...field}
                        error={
                          !!fieldState.error &&
                          fieldState.isTouched &&
                          i18n.formatMessage({
                            id: fieldState.error.message,
                          })
                        }
                      />
                    )}
                  />
                </Box>
              )}
            </Box>
          )}

          <Box>
            <Button type="submit" loading={form.formState.isSubmitting}>
              {ctaLabel}
            </Button>
            {submitError && (
              <FormHelperText error>
                {i18n.formatMessage({
                  id:
                    activeForm === 'login'
                      ? 'userAccount.serverErrors.wrong_password'
                      : 'userAccount.serverErrors.generalError',
                })}
              </FormHelperText>
            )}
          </Box>
        </form>
      )}
    </Dialog>
  );
};

export default memo(NotifyMeModal);
