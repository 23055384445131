import { PDPModules } from '@finn/auto-ui/types/cosmicModules';
import { SpacingWrapper } from '@finn/platform-modules';
import { GenericVehicleDetails, Review } from '@finn/ua-vehicle';

import { PageDataProvider } from '~/contexts/PageData';
import { ModuleType } from '~/types/UIModules';
import CosmicModule from '~/ui-modules/CosmicModule';

export type CosmicModulesProps = {
  reviews: Review[];
  pdpModules: PDPModules;
  vehicle: GenericVehicleDetails;
};

export const CoscmicModules = ({ reviews, pdpModules }: CosmicModulesProps) => {
  const { modules } = pdpModules.metadata;

  const renderModule = (module: ModuleType) => {
    return (
      <SpacingWrapper key={module.slug}>
        <CosmicModule data={module} />
      </SpacingWrapper>
    );
  };

  return (
    <PageDataProvider reviews={reviews}>
      {modules.map(renderModule)}
    </PageDataProvider>
  );
};

export default CoscmicModules;
