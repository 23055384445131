import { PDPModules } from '@finn/auto-ui/types';
import { SWR_CACHE_TIME_LONG } from '@finn/ua-constants';
import { GenericVehicleDetails } from '@finn/ua-vehicle';
import { ProductCard, Slider } from '@finn/ui-modules';
import { config, createFetcher, trackEvent } from '@finn/ui-utils';
import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import { ProductSlider } from './ProductSlider';
import recommendations from './recommendations.json';

const useStyles = makeStyles((theme) => ({
  productItem: {
    width: 280,
    [theme.breakpoints.up('sm')]: {
      width: 360,
    },
  },
  buttonWrapper: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(7.5),
    },
  },
}));

const fetcher = createFetcher({
  baseURL: config.PRODUCT_API_URL,
  withCredentials: false,
});

interface RecommendedResponse {
  results: GenericVehicleDetails[];
}

export const RecommendedVehicles: React.FunctionComponent<{
  id: string;
  pdpModules: PDPModules;
}> = ({ id, pdpModules }) => {
  const classes = useStyles();
  const ids = recommendations[id];

  const { data } = useSWR<RecommendedResponse>(
    {
      url: ids && `/cars-by-ids?cars_ids=${ids.join(',')}`,
    },
    fetcher,
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
      dedupingInterval: SWR_CACHE_TIME_LONG,
    }
  );

  const sortedData =
    ids &&
    ids
      .map((carId) => data?.results.find((item) => item.uid === carId))
      .filter(Boolean);

  let content = new Array(3).fill(true).map((_, idx) => (
    <div
      key={idx}
      role="status"
      className={`h-72 max-w-sm animate-pulse ${classes.productItem}`}
    >
      <div className="bg-iron dark:bg-iron-700 mb-4 h-52 rounded" />
      <div className="bg-iron dark:bg-iron-700 mb-4 h-4 w-36 rounded" />
      <div className="bg-iron dark:bg-iron-700 mb-4 h-4 w-14 rounded" />
    </div>
  ));

  if (data) {
    content = sortedData.map((vehicle, idx) => (
      <div
        key={vehicle.id}
        data-testid="product-card"
        className={classes.productItem}
      >
        <ProductCard
          vehicle={vehicle}
          shouldShowPlaceholder={false}
          position={idx + 1}
        />
      </div>
    ));
  }

  const i18n = useIntl();

  useEffect(() => {
    if (!ids) {
      trackEvent('No PDP Recommendations Found', { id });
    }
  }, [id, ids]);

  return (
    <div className="pt-20">
      {ids && (
        <Slider
          title={i18n.formatMessage({ id: 'pdp.interlinking.title' })}
          titleHeadingSize={3}
        >
          {content}
        </Slider>
      )}
      {!ids && <ProductSlider pdpModules={pdpModules} />}
    </div>
  );
};
